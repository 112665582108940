<template>

	<div id="OndeComprar" v-if="dataReady">

		<div id="photo-header" class="container-fluid d-flex align-items-center" v-if="dataReady ">
			
			<div class="container text-center">
				<h1>{{ pageinfo.titulopag }}</h1>
				<h2>{{ pageinfo.subtitulopag }}</h2>
			</div>
			
			<img src="@/images/grunge-border-1.png" class="grunge">
		</div>

		<div ref="mapScroll" class="container text-center mb50">
			<img src="@/images/textos/pontos-de-venda.svg" style="max-width: 100%;" alt="Pontos de Venda">
		</div>

		<div class="container mb100">
			<div id="store-map" class="moldura4"></div>
		</div>

		<div class="container mb100">

			<div class="row">

				<div class="store-info col-12 col-md-4 col-lg-3 mb30" v-for="loja in lojas" :key="loja.nome">
					<h3>{{ loja.localidade }}</h3>
					<h4>{{ loja.nome }}</h4>
					<p>
						<a href="#" v-on:click.prevent="moveMap([loja.mapa.lat,loja.mapa.lng]);">
							{{ loja.endereco }}<br>
							<span v-if="loja.codpostal">{{ loja.codpostal }}</span><br>
							<span v-if="loja.pais">{{ loja.pais }}</span><br>
							<span v-if="loja.telefone">Tel: {{ loja.telefone }}</span>
						</a>
					</p>
				</div>

			</div>

		</div>

		<MainFooter />

	</div>

</template>

<script>
/* IMPORT SCRIPTS */
import MainFooter from "@/components/footer.vue";
import globals from '../config/globals';
import {bus} from '@/main';
import axios from "axios";
import L from 'leaflet';

/* IMPORT IMAGES */
import mapMarker from '../images/map-marker.svg';
import mapMarkerShadow from '../images/map-marker-shadow.svg';

/* DB CONNECTIONS */
const requestOne = axios.get(globals.COCKPIT_SERVER+"singletons/get/ondecomprar"+globals.COCKPIT_TOKEN);
const requestTwo = axios.get(globals.COCKPIT_SERVER+"collections/get/lojas"+globals.COCKPIT_TOKEN+"&sort[localidade]=1");

export default {
	name: "OndeComprar",
	components: {
		MainFooter
	},
	data() {
		return {
			dataReady: false,
			pageinfo: null,
			map: null,
			tileLayer: null,
			lojas: null
		};
	},
	beforeCreate: function() {
		document.body.className = 'pag-onde-comprar';
	},
	mounted: async function () {
		await axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
			this.pageinfo = responses[0].data
			this.lojas = responses[1].data.entries
			this.dataReady = true
			this.changeMetaData()
			bus.$on('transition-done', this.prepareMap)
		}))
	},
	metaInfo() {
		return {
			title: 'Onde Comprar'
		}
	},
	methods: { 
		changeMetaData() {
			this.$parent.metaTitle = this.$metaInfo.title;
			this.$parent.metaDescription = this.pageinfo.subtitulopag;
		},	
		prepareMap() {
			console.log('prepare map');
			this.initMap()
			this.initMarkers()
		},
		initMap() {
			console.log('run initMap');
			this.map = L.map('store-map', { scrollWheelZoom: false }).setView([38.707342, -9.137426], 9);
			this.tileLayer = L.tileLayer('https://api.mapbox.com/styles/v1/tiagorio/ckfy00xa00r0319ojzutv2e34/tiles/256/{z}/{x}/{y}?access_token={accessToken}', {
				attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
				maxZoom: 20,
				id: 'mapbox.streets',
				accessToken: 'pk.eyJ1IjoidGlhZ29yaW8iLCJhIjoiY2sxMmllMHFsMDloazNqbXFrMm90b2ZkMCJ9.FTVxuBywZ2XGF2hzZ1577w',
				scrollWheelZoom: false
			});
			this.tileLayer.addTo(this.map);

			this.myIcon = L.icon({
				iconUrl:mapMarker,
				shadowUrl:mapMarkerShadow,
				iconSize:[40,54],
				iconAnchor:[20,54],
				popupAnchor:[0,-40],
				tooltipAnchor:[16,-28],
				shadowSize:[60,54],
				shadowAnchor: [24, 54]
			});

			this.map.on('click', function() {
				if (this.scrollWheelZoom.enabled()) {
					this.scrollWheelZoom.disable();
				}
				else {
					this.scrollWheelZoom.enable();
				}
			});
		},
		initMarkers() {
			this.lojas.forEach((loja) => {
				loja.leafletObject = L.marker([loja.mapa.lat, loja.mapa.lng], {icon: this.myIcon}).addTo(this.map)
					.bindPopup('<b>'+loja.nome+'</b><br><a href="http://www.google.com/maps/place/'+loja.mapa.lat+','+loja.mapa.lng+'" target="_blank">Direcções</a>');
				loja.leafletObject.addTo(this.map);
			});
		},
		moveMap(latlng) {
			this.$refs.mapScroll.scrollIntoView({block: "start", behavior: "smooth"});
			this.map.setView(latlng, 14);
		}
	},
};
</script>